<template>
  <div id="reactivos">
    <MenuBase
      :tipo="'reactivos'"
      :headers="headers"
      :items="$store.state.tabla"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-chemical-weapon'"
      :verTabla="true"
      :loading="!$store.state.tabla.length"
    />
    <Reactivo :modo="modoReactivo" :dialogR.sync="dialogReactivo" :nreactivo.sync="this.campoOpcion" :origen.sync="origen"/>
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Reactivo from '@/views/reactivo.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Reactivo
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Reactivos',
    dialog: false,
    tituloOpciones: 'Opciones reactivo: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoReactivo: 'NUEVO',
    dialogReactivo: false,
    origen: 'tabla'
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre' },
        { text: 'MUESTRA ASOCIADA', align: 'start', value: 'muestra' },
        { text: 'NOTAS', align: 'start', value: 'notas' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVO REACTIVO', color: 'primary', accion: 'nuevoReactivo' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR REACTIVO', color: 'primary', accion: 'editarReactivo' }
        // { id: 2, nombre: 'QUITAR REACTIVO', color: 'error', accion: 'quitarReactivo' }
      ]
    },
    clicFila (fila) {
      this.campoOpcion = fila.nombre
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevoReactivo') {
        this.modoReactivo = 'NUEVO'
        this.dialogReactivo = true
      } else if (accion === 'editarReactivo') {
        this.modoReactivo = 'EDITAR'
        this.dialogReactivo = true
      } else if (accion === 'quitarReactivo') {
        alert('Se quitará éste reactivo como parámetro seleccionable')
      }
    },
    cargarItems () {
      this.cargarTabla('reactivos/todo', 'cambiarTabla', 'cargarTablaReactivos')
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('reactivos') === true) {
          this.interfaz()
          this.cargarItems()
        } else { this.$router.push('/menu') }
      }
    }
  },
  created () {
    this.haySesion()
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
